<script>
export default {
    props: ['oldPhone'],
    data() {
        return {
            phone: '+38 (0'
        }
    },mounted() {
        if (this.oldPhone) {
            this.phone = this.oldPhone;
        }
    },
    watch: {
        phone: function (newValue, oldValue) {
            if (newValue == "+3_ (___) ___-__-__") {
                this.phone = "+38 (0";
            }

            if (newValue == "") {
                this.phone = "+38 (0";
            }

        },
    }
}
</script>
